import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import cx from 'classnames'

import {
  NOTIFY_EXPIRING_CARD_MODAL,
  EXPIRING_CARD_LAST_NOTIFIED,
  FEATURE_LIMIT_MODAL,
  NO_PLAN_MODAL
} from 'constants/account'
import { TRANSITION_TIMING } from 'constants/sidebar'
import { LISTING_GRADER_BASE, DASHBOARD_URL } from 'constants/routes'
import { NAVBAR_STYLE } from 'constants/styles'
import { COBALT_APP_TYPE } from 'constants/themes'

import { shouldNotifyExpiringCard } from 'helpers/account'
import { clearGlobalData } from 'helpers/storage'

import { AccountStatusBanner } from 'ui_elements/AccountStatusBanner/AccountStatusBanner'
import { FeatureLimitModal } from 'ui_elements/FeatureLimitModal'

import { getCookie } from 'services/cookies'
import { setSiftSession } from 'services/sift'

import { isFeatureEnabled } from 'helpers/features'
import { Features } from 'constants/feature_names'

import LoadingPage from 'components/common/LoadingPage'
import { Navbar } from 'components/navbar/Navbar/Navbar'
import { UpgradeSearchLimitModal } from 'components/feature_limit_modals/SearchLimitModal/UpgradeSearchLimitModal'

import Routes from '../routes'

import { NoPlanModal } from './feature_limit_modals/NoPlanModal/NoPlanModal'
import ExpiringCreditCardModal from './account/ExpiringCreditCardModal'
import MwsSyncStatusbar from './MwsSyncStatusbar'
import Sidebar from './sidebar'
import { MobileMenu } from './mobile_menu/MobileMenu'

const PAGES_WITHOUT_NAVBAR = [
  '/login',
  'setup',
  'password',
  'amazing',
  'add-user',
  'extension-uninstall',
  'onboard',
  'registrations',
  'checkout',
  'cancel',
  '/cancel-confirm',
  '/404-page',
  'unsubscribe_email',
  /\/$/
]

// TODO - this is quite hacky
const PAGES_WITH_PADDING = ['/admin']
const SHOW_BANNER_STATUS = [0, 3, 4, 5]

const getRouterPadding = ({ locked, isMobileNavEnabled }) => {
  if (isMobileNavEnabled) return 0

  return locked ? NAVBAR_STYLE.width : '52px'
}

const StyledContent = styled.div`
  // rewrites the margin-top: -10px on the legacy SASS file
  ${props => props.isMobileNavEnabled && `margin-top: 0 !important`}
`

const RouterWrapper = styled.div`
  padding: ${props => (props.hasPadding ? '30px' : 'unset')};
  transition: margin-left 0.6s ease;
  width: 100%;
  margin-top: ${props => (props.isStatusbarVisible ? '125px' : '59px')};
  overflow: visible;
  padding-left: ${props =>
    getRouterPadding({
      locked: props.locked,
      isMobileNavEnabled: props.isMobileNavEnabled
    })};
  transition: padding-left 0.3s ${TRANSITION_TIMING};
`

class AppComp extends Component {
  state = {
    isFullScreenOverlay: false
  }

  constructor(props) {
    super(props)
    // this is a bit odd in react land, but it saves passing state all over the place and having multiple components having to touch global data etc
    window.addEventListener('fullScreenOverlay', event =>
      this.setState({ isFullScreenOverlay: event.detail })
    )
  }

  componentDidMount() {
    const { pathname } = this.props.location

    if (getCookie(process.env.REACT_APP_AUTH_TOKEN_COOKIE)) {
      const {
        loadGlobalData,
        loadChecklistData,
        fetchCardInfo,
        getAmazonSellerAccounts,
        addIdentifyRequest,
        identifyRequestsAreInProgress,
        executeIdentifyRequests
      } = this.props

      loadGlobalData()
      loadChecklistData()
      fetchCardInfo()
      getAmazonSellerAccounts()

      this.trackServerSideIdentify(
        addIdentifyRequest,
        identifyRequestsAreInProgress,
        executeIdentifyRequests
      )
    } else {
      clearGlobalData()
    }
    this.props.addLocationHistory(pathname)

    setSiftSession()
  }

  componentDidUpdate(prevProps) {
    const { locationHistory, loggedIn } = prevProps.sessions

    const {
      location: { pathname },
      updateActivePpcAccounts,
      addLocationHistory,
      globalData: {
        user,
        membershipInfo: { hasActiveMembership },
        pendo,
        modal,
        flagData
      },
      cardInfo,
      loadGlobalData,
      loadChecklistData,
      sessions,
      fetchCardInfo,
      getAmazonSellerAccounts,
      setGlobalModal,
      enableCNTheme,
      addIdentifyRequest,
      identifyRequestsAreInProgress,
      executeIdentifyRequests
    } = this.props

    if (locationHistory !== pathname) {
      updateActivePpcAccounts(pendo.account.activePPCAccounts)
      addLocationHistory(pathname)
    }

    if (
      getCookie(process.env.REACT_APP_AUTH_TOKEN_COOKIE) &&
      !loggedIn &&
      !!sessions.loggedIn
    ) {
      loadGlobalData()
      fetchCardInfo()
      getAmazonSellerAccounts()

      this.trackServerSideIdentify(
        addIdentifyRequest,
        identifyRequestsAreInProgress,
        executeIdentifyRequests
      )
    }

    if (
      shouldNotifyExpiringCard(user, hasActiveMembership, cardInfo) &&
      modal !== NOTIFY_EXPIRING_CARD_MODAL
    ) {
      window.localStorage.setItem(
        EXPIRING_CARD_LAST_NOTIFIED,
        moment(Date.now()).format('YYYY-MM-DD')
      )
      setGlobalModal(NOTIFY_EXPIRING_CARD_MODAL)
    }

    const prevLang = prevProps.globalData?.user?.selected_language
    const currLang = user?.selected_language
    const checkout_locale = user?.checkout_locale

    if (prevLang !== currLang) {
      enableCNTheme(
        currLang === 'zh-Hant' &&
          isFeatureEnabled(Features.CN_THEME, flagData) &&
          checkout_locale === 'CN'
      )
    }

    const prevObjective = prevProps.globalData?.user?.objective
    const currObjective = user?.objective

    if (prevObjective !== currObjective) {
      loadChecklistData()
    }
  }

  renderCancellationBanner = () => {
    const {
      globalData: {
        membershipInfo: {
          hasActiveMembership,
          membershipAdmin,
          membershipAdminEmail,
          membershipStatus,
          daysTilExpiration,
          has_launch_access,
          payment_plan
        }
      },
      reactivateMembership,
      account: {
        billing: { periodEnd },
        reactivate
      }
    } = this.props

    if (window.location.hash === '#/account/subscriptions') return

    if (has_launch_access && SHOW_BANNER_STATUS.includes(membershipStatus)) {
      return (
        <AccountStatusBanner
          reactivate={reactivate}
          reactivateMembership={reactivateMembership}
          hasActiveMembership={hasActiveMembership}
          membershipAdmin={membershipAdmin}
          membershipAdminEmail={membershipAdminEmail}
          membershipStatus={membershipStatus}
          daysTilExpiration={daysTilExpiration}
          periodEnd={periodEnd}
          paymentPlan={payment_plan}
          hasPadding={this.hasPadding()}
        />
      )
    }

    return null
  }

  trackServerSideIdentify = (
    addIdentifyRequest,
    identifyRequestsAreInProgress,
    executeIdentifyRequests
  ) => {
    addIdentifyRequest({
      traits: {
        languageBrowser: window?.navigator?.language
      }
    })

    if (!identifyRequestsAreInProgress()) {
      executeIdentifyRequests()
    }
  }

  hasPadding() {
    // TODO - I don't really like this - it's a bit hacky, but for speed/simplicity it is what it is for now
    // it's basically the same way we do routes without navbars
    // according to design, the plan is everything should end up with this padding once there's time to do this
    const { pathname } = this.props.history.location

    return PAGES_WITH_PADDING.some(path => pathname.startsWith(path))
  }

  renderContent = () => {
    const {
      history: {
        location: { pathname }
      }
    } = this.props

    // Test if route needs a navbar
    const renderNavbar = !PAGES_WITHOUT_NAVBAR.reduce(
      (a, b) => a || RegExp(b).test(pathname),
      false
    )

    if (renderNavbar) {
      return this.renderNavbarElement()
    }

    return this.renderNoNavbarElement()
  }

  renderNavbarElement = () => {
    const {
      globalData: {
        initialLoad,
        employeeWithAccess,
        user,
        isImpersonating,
        flagData,
        trialAlerts,
        membershipInfo,
        appType,
        cnTheme,
        featureLimits
      },
      sidebar: { collapsed, locked, ignoreToggleState, expandedOption },
      toggleSidebarLocked,
      expandSidebarOption,
      push,
      location,
      isStatusBarVisible
    } = this.props

    const isCobalt = appType === COBALT_APP_TYPE

    const isMobileNavEnabled = isMobile && !isCobalt

    const { isFullScreenOverlay } = this.state
    const { selected_language } = user
    const wordWrappingLanguages = ['es-ES', 'fr-FR']
    const enableWrapping = wordWrappingLanguages.includes(selected_language)
    const contentCss = cx({
      'cn-white': cnTheme?.cnThemeEnabled && cnTheme?.cnThemeChoice === 'white',
      'cn-black': cnTheme?.cnThemeEnabled && cnTheme?.cnThemeChoice === 'black'
    })

    if (
      initialLoad?.isLoading &&
      getCookie(process.env.REACT_APP_AUTH_TOKEN_COOKIE)
    ) {
      return <LoadingPage />
    }

    return (
      <>
        <Navbar
          isImpersonating={isImpersonating}
          isFullScreenOverlay={isFullScreenOverlay}
          user={user}
          appType={appType}
          locked={locked}
        />
        <MwsSyncStatusbar
          sidebarOpen={locked}
          membershipInfo={membershipInfo}
          history={this.props.history}
        />
        <StyledContent
          id="app-content"
          className={contentCss}
          isMobileNavEnabled={isMobileNavEnabled}>
          {!isFullScreenOverlay && !isMobileNavEnabled && (
            <Sidebar
              collapsed={collapsed}
              locked={locked}
              cnTheme={cnTheme}
              ignoreToggleState={ignoreToggleState}
              admin={employeeWithAccess}
              expandedOption={expandedOption}
              toggleSidebar={toggleSidebarLocked}
              expandSidebarOption={expandSidebarOption}
              currentPath={location.pathname}
              flagData={flagData}
              membershipInfo={membershipInfo}
              user={user}
              appType={appType}
              push={push}
              enableWrapping={enableWrapping}
              isImpersonating={isImpersonating}
              featureLimits={featureLimits}
            />
          )}
          {isMobileNavEnabled && !isFullScreenOverlay && (
            <MobileMenu
              collapsed={collapsed}
              locked={locked}
              cnTheme={cnTheme}
              ignoreToggleState={ignoreToggleState}
              admin={employeeWithAccess}
              expandedOption={expandedOption}
              toggleSidebar={toggleSidebarLocked}
              expandSidebarOption={expandSidebarOption}
              currentPath={location.pathname}
              flagData={flagData}
              membershipInfo={membershipInfo}
              user={user}
              appType={appType}
              push={push}
              enableWrapping={enableWrapping}
            />
          )}
          <RouterWrapper
            collapsed={collapsed}
            locked={locked}
            ignoreToggleState={ignoreToggleState}
            isMobileNavEnabled={isMobileNavEnabled}
            isStatusbarVisible={
              isStatusBarVisible ||
              (trialAlerts?.launch?.daysRemaining &&
                membershipInfo?.membershipStatus !== 0)
            }
            hasPadding={this.hasPadding()}
            style={{ position: 'relative' }}>
            {this.renderCancellationBanner()}
            <Routes {...this.props} />
          </RouterWrapper>
        </StyledContent>
      </>
    )
  }

  renderNoNavbarElement = () => {
    return (
      <div>
        <Routes {...this.props} />
      </div>
    )
  }

  renderFeatureLimitModals = () => {
    return (
      <>
        <UpgradeSearchLimitModal />
      </>
    )
  }

  renderGlobalModal = modal => {
    const {
      setGlobalModal,
      history,
      location: { pathname },
      account: { gatedFeature },
      cardInfo
    } = this.props

    if (modal === NOTIFY_EXPIRING_CARD_MODAL) {
      return (
        <ExpiringCreditCardModal
          setGlobalModal={setGlobalModal}
          history={history}
          cardInfo={cardInfo}
        />
      )
    }

    if (modal === NO_PLAN_MODAL) {
      return <NoPlanModal />
    }

    // In Listing Builder we show a custom feature limit modal. The check here
    // is to prevent the global feature limit modal from showing up there
    if (
      modal === FEATURE_LIMIT_MODAL &&
      pathname !== LISTING_GRADER_BASE &&
      pathname !== DASHBOARD_URL &&
      !pathname.match(/keyword\/listings\/[0-9]+/)
    ) {
      return (
        <FeatureLimitModal
          toggleModal={() => setGlobalModal(null)}
          limitType={gatedFeature?.limit_type}
          scope={gatedFeature?.scope}
        />
      )
    }

    return null
  }

  render() {
    const { globalData } = this.props

    return (
      <div className="app">
        {this.renderGlobalModal(globalData.modal)}
        {this.renderFeatureLimitModals()}
        {this.renderContent()}
      </div>
    )
  }
}

AppComp.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  setGlobalModal: PropTypes.func,
  globalData: PropTypes.shape({
    membershipInfo: PropTypes.shape({
      payment_plan: PropTypes.string
    }),
    user: PropTypes.shape({
      selected_language: PropTypes.string
    }),
    flagData: PropTypes.oneOfType([PropTypes.object])
  }),
  enableCNTheme: PropTypes.func.isRequired
}

AppComp.defaultProps = {
  globalData: {
    membershipInfo: {
      payment_plan: ''
    }
  }
}

export default AppComp
